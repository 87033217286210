.border-*{
    border-color: $border-color;
}
.border-tl-radius, .border-tx-radius{
    border-top-left-radius: 4px;
}

.border-tr-radius, .border-tx-radius{
    border-top-right-radius: 4px;
}
.border-bl-radius, .border-bx-radius{
    border-bottom-left-radius: 4px;
}

.border-br-radius, .border-bx-radius{
    border-bottom-right-radius: 4px;
}

.border-tl-radius-0, .border-tx-radius-0{
    border-top-left-radius: 0;
}

.border-tr-radius-0, .border-tx-radius-0{
    border-top-right-radius: 0;
}
.border-bl-radius-0, .border-bx-radius-0{
    border-bottom-left-radius: 0;
}

.border-br-radius-0, .border-bx-radius-0{
    border-bottom-right-radius: 0;
}

.border-radius{
    border-radius: 4px;
}