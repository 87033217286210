button {
  outline: none;

  &:focus {
    outline: none;
  }
}

.btn-lg {
  height: 48px;
}

.btn-link {
  &:hover {
    text-decoration: none;
  }
}

.btn-xs {
  padding: .2rem .6rem;
  font-size: .75rem;
  border-radius: .15rem;
}

.btn-rounded {
  border-radius: 50px;
}

.btn-mw110 {
  min-width: 110px;
}

.btn-white {
  background-color: $white;
  color: $primary;
  &:hover {
    color: $primary;
  }
}

// .btn-success {
//   color: $white;
//   &:hover, &:focus, &:visited {
//     color: $white;
//   }
// }
@each $color,
$value in $theme-colors {
  .btn-#{$color} {
    --#{$prefix}btn-active-color: #{$white};
    --#{$prefix}btn-disabled-color: #{$white};
    @if $color !="light" {
      color: $white;

      &:hover,
      &:focus,
      &:visited {
        color: $white;
      }

      &:active,
      &:first-child:active,
      &.active,
      &.show {
        color: $white;
      }
    }
    &:hover, &:active {
      background-color: $value;
      border-color: $value; 
    }
  }
  .btn-light{
    color: $default;
    &:hover, &:active {
      color: $default;
    }
  }
  .btn-outline-#{$color} {
    --#{$prefix}btn-active-color: #{$white};
    &:hover, &:active {
      @if $color !="light" {
      color: $white;
      }
    }
  }
}

.btn-outline-white {
  border-color: $white;
  color: $white;

  &:hover {
    background-color: $white;
    color: $primary;
  }
}

@mixin btn-icon-soft-variant($bg) {
  background-color: rgba($bg, 0.15);
  border-color: rgba($bg, 0.15);
  color: $bg;
}


// avatar height
.btn-icon {
  height: 2.25rem;
  width: 2.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 0.3rem;
  // &:hover{
  //   background-color: rgba($black, 0.07);
  // }
}

button.btn-icon {
  // background: none;
  border: none;
  // padding: 0;
  // color: $primary;
}

@each $color,
$value in $theme-colors {
  .btn-icon-soft-#{$color} {
    &:hover {
      @include btn-icon-soft-variant($value);
    }
  }

  .btn-soft-#{$color} {
    @include btn-icon-soft-variant($value);
    &:hover, &:active, &:first-child:active {
      @include btn-icon-soft-variant($value);
    }
  }
}

.dropdown.show {

  @each $color,
  $value in $theme-colors {
    .btn-icon-soft-#{$color} {
      @include btn-icon-soft-variant($value);
    }
  }
}

.btn-icon-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.btn-icon-sm {
  height: 2rem;
  width: 2rem;
}

.btn-icon-md {
  height: 2.5rem;
  width: 2.5rem;
}

.btn-icon-lg {
  height: 3rem;
  width: 3rem;
}

.btn-icon-xl {
  height: 4rem;
  width: 4rem;
}

.btn-icon-xxl {
  height: 5.5rem;
  width: 5.5rem;
}

.dropdown-toggle-split {

  @each $color,
  $value in $theme-colors {
    &.btn-#{$color} {
      &:before {
        @if($color =='light') {
          border-color: $gray-600;
        }

        @else {
          border-color: $value;
        }
      }
    }
  }

  &:before {
    display: block;
    content: ' ';
    @include border(left, 1px, solid, transparent);
    position: absolute;
    top: 5px;
    left: 0;
    bottom: 5px;
    opacity: .3;
  }
}

.brand-btn {
  padding-left: 50px;
  border: none;

  .brand-icon {
    @include position(absolute, 0, null, 0, 0);
    background-color: rgba($black, 0.3);
    width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.brand-btn-right {
  padding-right: 50px;
  border: none;

  .brand-icon {
    @include position(absolute, 0, 0, 0, null);
    background-color: rgba($black, 0.3);
    width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.btn-label {
  margin: -.55rem .9rem -.55rem -.9rem;
  padding: .6rem .9rem;
  background-color: rgba(50, 58, 70, .1);
}

.btn-label-right {
  margin: -.45rem -.9rem -.45rem .9rem;
  padding: .45rem .9rem;
  background-color: rgba(50, 58, 70, .1);
  display: inline-block;
}