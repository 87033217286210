.form-group {
  margin-bottom: 15px;
}
.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .form-group {
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    align-items: center;
    
    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }
  }
}

.form-control{
  // height: 40px;
  padding: $input-btn-padding-y $input-btn-padding-x;
}
.form-control-lg{
  height: 48px;
  padding: $input-btn-padding-x;
  font-size: 1rem;
  &:focus {
    background-color: transparent;
  }
}

.form-control-light {
  background: $light;
  &:focus {
    border-color: transparent;
    background: $light;
  }
}

.form-group.is-invalid{
  .invalid-feedback{
    display: block;
  }
}

.floating-label {
  position: relative;

  .form-control {
    &:focus {
      border-color: $primary;

      &+label {
        top: -10px;
        @include transform(scale(0.9));
        background: $white;
        color: $primary;
        transform-origin: left;
      }
    }
  }

  label {
    @include position(absolute, 14px, null, null, 10px, 3);
    padding: 0 5px;
    cursor: text;
    @include transition(all 0.25s ease-out 0.1s);
  }

  &.is-valid,
  &.enable-floating-label {
    margin-bottom: 1.25rem;
    .form-control {
      border-color: $gray-400;

      &:focus {
        border-color: $primary;

        &+label {
          color: $primary;
        }
      }
    }

    label {
      top: -10px;
      @include transform(scale(0.9));
      background: $white;
      color: $body-color;
      transform-origin: left;
    }
  }

  &.is-invalid {
    .form-control {
      border-color: $danger;

      &:focus {
        &+label {
          color: $danger;
        }
      }
    }

    label {
      top: -10px;
      @include transform(scale(0.9));
      background: $white;
      color: $danger;
      transform-origin: left;
    }
  }
}

@each $key, $val in $theme-colors {
  .custom-checkbox, .custom-radios {
    &.checkbox-#{$key} {
      .form-check-input:checked{
        ~ .form-check-label {
          &:before {
            background: $val;
          }
          &:after {
            color: $white;
          }
        }
      }
    }
  }
}

.custom-checkbox, .custom-radio {
  position: relative;
  border: none;
  padding-left: 28px;
  min-height: auto;
  margin-bottom: 0;
  .form-check-input {
    background-image: none;
    background-color: darken($light, 5);
    border-color: darken($light, 5);
    width: 20px;
    height: 20px;
    margin-left: 0;
    margin-top: 2px;
    @include position(absolute, 0, 0, 0, 0);
  }
  .form-check-input:checked ~ .form-check-label::after{
    font-family: $boxIcons;
    content: "\ea0f";
    background: none;
    font-size: 1rem;
    @include position(absolute, -1px, null, 0, 0);
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.custom-radio {
  .form-check-input:checked ~ .form-check-label::after{
    content: '';
    width: 8px;
    height: 8px;
    background-color: darken($light, 25);
    border-radius: 100%;
    top: 0;
    margin: auto;
    left: 6px;
  }
}
.was-validated {
  .custom-checkbox {
    .form-check-input:valid:checked ~ .form-check-label::after{
      color: $white;
    }
  }
}

.input-date{
  position: relative;
  .form-control{
    padding-right: 2.5rem;
  }
  .date-icon{
    position: absolute;
    top: 15px;
    // @include transform(translateY(-50%));
    right: 0.75rem;
    bottom: 0;
    opacity: 0.6;
    pointer-events: none;
    &.active{
      opacity: 1;
      color: $primary;
    }
  }
}

.input-date-sm {
  .date-icon {
    top: 10px;
  }
}

.custom-switch {
  padding-left: $form-switch-width;
  .form-check-input {
    height: $form-check-height;
  }
}

.custom-range{
  &::-webkit-slider-thumb:active{
    background-color: rgba($primary, 0.8);
  }
  &::-moz-range-thumb:active{
    background-color: rgba($primary, 0.8);
  }
  &::-ms-thumb:active{
    background-color: rgba($primary, 0.8);
  }
}
.form-control[type=file] {
  border-radius: $input-border-radius !important;
}
.custom-file {
  position: relative;
  .form-control{
    &::file-selector-button {
      background: none;
      border: none;
    }
    &:hover {
      &::file-selector-button {
        background: none !important;
        border: none;
      }
    }
    &:focus {
      z-index: 0;
      background-color: transparent !important;
      &::file-selector-button {
        background: none !important;
        border: none;
      }
    }
  }
  .form-control::-webkit-file-upload-button ~ span {
    opacity: 0;
  }
  .custom-file-label {
    @include position(absolute, 4px, 4px, 4px, null);
    background-color: $light;
    padding: $btn-padding-y $btn-padding-x;
    font-size: 13px;
    border-radius: $input-border-radius !important;
  }
}

// .custom-file-input {
//   position: relative;
//   z-index: 2;
//   width: 100%;
//   height: $custom-file-height;
//   margin: 0;
//   opacity: 0;

//   &:focus ~ .custom-file-label {
//     border-color: $custom-file-focus-border-color;
//     box-shadow: $custom-file-focus-box-shadow;
//   }

//   // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
//   &[disabled] ~ .custom-file-label,
//   &:disabled ~ .custom-file-label {
//     background-color: $custom-file-disabled-bg;
//   }

//   @each $lang, $value in $custom-file-text {
//     &:lang(#{$lang}) ~ .custom-file-label::after {
//       content: $value;
//     }
//   }

//   ~ .custom-file-label[data-browse]::after {
//     content: attr(data-browse);
//   }
// }

// .custom-file-label {
//   position: absolute;
//   top: 0;
//   right: 0;
//   left: 0;
//   z-index: 1;
//   height: $custom-file-height;
//   padding: $custom-file-padding-y $custom-file-padding-x;
//   font-family: $custom-file-font-family;
//   font-weight: $custom-file-font-weight;
//   line-height: $custom-file-line-height;
//   color: $custom-file-color;
//   background-color: $custom-file-bg;
//   border: $custom-file-border-width solid $custom-file-border-color;
//   @include border-radius($custom-file-border-radius);
//   @include box-shadow($custom-file-box-shadow);

//   &::after {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 3;
//     display: block;
//     height: $custom-file-height-inner;
//     padding: $custom-file-padding-y $custom-file-padding-x;
//     line-height: $custom-file-line-height;
//     color: $custom-file-button-color;
//     content: "Browse";
//     @include gradient-bg($custom-file-button-bg);
//     border-left: inherit;
//     @include border-radius(0 $custom-file-border-radius $custom-file-border-radius 0);
//   }
// }