// 
// tables.scss
//

//Table centered (Custom)
// .table>:not(caption)>*>* {
//     border-bottom: $border-color;
//     box-shadow: none;
// }
// .table-bordered>:not(caption)>*>* {
//     border-color: $border-color;
// }
// .table-bordered>:not(caption)>* {
//     border-color: $border-color;
// }
// .table-centered {
//     td,th {
//         vertical-align: middle !important;
//     }
// }

.table>:not(caption)>*>* {
    border-bottom: none;
    box-shadow: none;
}
.table-bordered>:not(caption)>* {
    border: none;
}
.table-bordered>:not(caption)>*>* {
    border-color: $border-color;
}
.table {
    th, td {
        border-top: 1px solid $border-color;
    }
    thead {
        th {
            border-bottom: 2px solid $border-color;
        }
    }
}

.table-borderless {
    th, td {
        border: none;
    }
    thead {
        th {
            border-bottom: none;
        }
    }
}

.table-striped {
    tbody tr:nth-of-type(odd) {
        background-color: $light;
    }
}

.table-bordered {
    th, td {
        border: 1px solid $border-color;
    }
}


// Tables fluid
.table-nowrap {
    th, td {
      white-space: nowrap;
    }
}

//No thead
.table-no-head{
    td:first-child{
        padding-left: 0;
    }
    td:last-child{
        padding-right: 0;
    }
}